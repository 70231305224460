<template>
	<div>
		<b-card no-body>
			<b-card title="" header-tag="header">
				<template v-slot:header>
					<product-form-nav link="image" :productDetails="productDetails" :productId="id"></product-form-nav>
				</template>
				<b-card-text>
					
						<div class="card-body">
							<div class="form-group row justify-content-center">
								<label class="col-form-label col-auto text-lg-right">Option / Variant</label>
								<div class="col-lg-4 col-md-9 col-sm-12">
									<b-form-group>
										<treeselect  v-model="optionValueId"  :max-height="200" 
									:default-expand-level="1" :options="variants"   />
									</b-form-group>
								</div>
							</div>
							<div class="text-center" v-show="loading">
								<div class="spinner-border" role="status">
								</div>
							</div>
							<div v-show="optionValueId && !loading">
								<div class="form-group row">
									<div class="col-md-12 col-lg-6 col-xl-4 mb-sm-5">
									<label>Default Image</label>

										<vue-dropzone ref="defaultDropzone" id="defaultDropzone"  @vdropzone-processing="removeAllFile('defaultDropzone')" @vdropzone-sending="sendingDefaultEvent" :options="dropzoneOptions" :useCustomSlot="true">
												<div class="dropzone-msg dz-message needsclick">
													<h3 class="dropzone-msg-title">Drop files here or click to upload.</h3>
												</div>
										</vue-dropzone>
									</div>
									<div class="col-md-12 col-lg-6 col-xl-4 mb-sm-5">
										<label>Try On Image</label>
										<vue-dropzone ref="tryOnDropzone"  id="tryOnDropzone" @vdropzone-processing="removeAllFile('tryOnDropzone')" @vdropzone-sending="sendingTryOnEvent" :options="dropzoneOptions"></vue-dropzone>
									</div>
									<div class="col-md-12 col-lg-6 col-xl-4 mb-sm-5">
										<label>Side View Image</label>
										<vue-dropzone ref="sideViewDropzone"  id="sideViewDropzone" @vdropzone-processing="removeAllFile('sideViewDropzone')" @vdropzone-sending="sendingSideEvent" :options="dropzoneOptions"></vue-dropzone>
									</div>
								</div>
								<div class="form-group d-flex">
									<div class="flex-fill">
									<label>Multtiple Images</label>

										<vue-dropzone ref="multipleImageDropzone"  id="multipleImageDropzone"  @vdropzone-sending="sendingMultipleEvent" @vdropzone-complete-multiple="multipleComplete" :options="dropzoneOptions"></vue-dropzone>
									</div>
								</div>
							
								<div class="pb-4">
									<h6>
										Image List
									</h6>

								</div>
								
								<draggable
										:list="images"
										:disabled="false"
										class="list-group mx-8"
										ghost-class="ghost"
										@change="checkMove"
										@start="dragging = true"
										@end="dragging = false"
								>
	  								
									<div class="mb-1 d-flex list-item align-items-center " v-for="(image,i) in images" :key="image.id">
										<div class="flex-fixed"><a href="#"><i class="la la-bars hint-text"></i>
												</a></div>
												<div class="mx-4 flex-fill">
										<img :src="image.image"  @click="index = i" alt="..." width="100" class="img-fluid img-thumbnail">
												</div>
										<ul class="mb-0 d-flex align-items-center justify-content-between list-unstyled">
											<li>
												<button @click="deleteImage(image.id)"><i class="la la-trash text-danger"></i>
												</button>
											</li>
										</ul>
									</div>
								</draggable>
							</div>
							
						</div>
						
						<div class="card-footer" >
							<b-row>
								<b-col class="text-right">
									<router-link :to="`/products`"  class="btn btn-light-primary mr-2">Submit</router-link>
								</b-col>
							</b-row>
						</div>
				</b-card-text>
			
			</b-card>
		</b-card>
		<CoolLightBox :items="images" srcName="image" :index="index" :fullScreen="true" @close="index = null"> </CoolLightBox>
	</div>
</template>

<script>
	import ProductFormNav from "./components/ProductFormNav.vue";
	import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
	import Swal from "sweetalert2";
	import draggable from 'vuedraggable'
	import CoolLightBox from "vue-cool-lightbox";
	import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
	//api services
	import ProductService from "@/core/services/api/product/product";
	import ImageService from "@/core/services/api/product/image";
	import JwtService from "@/core/services/jwt.service";
	import OptionGroupService from "@/core/services/api/product/option";
	export default {
		components: {
			ProductFormNav,
			draggable,
			CoolLightBox
		},
		data() {
			return {
				id: this.$route.params.id?parseInt(this.$route.params.id):null,
				productDetails:{},
				form: {
					image: [],
					imageType: null,
				},
				imgPath:process.env.VUE_APP_API_STORAGE_URL,
				filters: {},
				url:'',
				dropzoneOptions: {
					url: process.env.VUE_APP_API_BASE_URL+'/api/admin/product/image/',
					thumbnailWidth: 200,
					thumbnailHeight: 100,
					addRemoveLinks: true,
					maxFilesize: 1,
					parallelUploads:20,
					acceptedFiles:"image/*",
					paramName:"image",
					headers: { "Authorization":`Bearer ${JwtService.getToken()}` }
				},
				imageList:[],
				variants:[],
				optionValueId: null,
				images:[],
				loading:false,
				index: null,
			};
		},
		created(){
			this.getProductById();
			this.getVariantList();
			// this.getImagesByProductId();
			this.dropzoneOptions.url = process.env.VUE_APP_API_BASE_URL+'/api/admin/product/image/'+this.id;
		},
		mounted() {
			this.$store.dispatch(SET_BREADCRUMB, [
				{
					title: "Dashboard",
					route: "/dashboard",
				},
				{
					title: "Products",
					route: "/products",
				},
				{
					title: "Manage Product : Image Upload",
				},
			]);
			this.$refs.multipleImageDropzone.dropzone.options.url = process.env.VUE_APP_API_BASE_URL+'/api/admin/product/images/'+this.id;
			this.$refs.multipleImageDropzone.dropzone.options.uploadMultiple = true;
			this.$refs.multipleImageDropzone.dropzone.options.paramName = "images";
		},
		computed: {
			
		},
		methods: {
			sort() {
				this.images = this.images.sort((a, b) => a.sort_order - b.sort_order);
			},
			checkMove: function(e) {
				this.images.forEach((value, index) => {
					const formData = new FormData();
            		formData.append("sort_order",index+1 );
					ImageService.updateSortOrder(value.id,formData);
				})
			},
			initImages(){
				if(this.imageList.length != 0){
					var defaultImage = this.imageList.find((e) => {return e.image_type == "default"})
					if(defaultImage){
						var file = { id:defaultImage.id ,size: 1234, name: "default", type: "image/png" };
						this.$refs.defaultDropzone.manuallyAddFile(file, process.env.VUE_APP_API_STORAGE_URL+defaultImage.image);
					}
					var sideImage = this.imageList.find((e) => {return e.image_type == "side"})
					if(sideImage){
						var file = { id:sideImage.id ,size: 1234, name: "side", type: "image/png" };
						this.$refs.sideViewDropzone.manuallyAddFile(file, process.env.VUE_APP_API_STORAGE_URL+sideImage.image);
					}
					var tryOnImage = this.imageList.find((e) => {return e.image_type == "try_on"})
					if(tryOnImage){
						var file = { id:tryOnImage.id ,size: 1234, name: "tryOn", type: "image/png" };
						this.$refs.tryOnDropzone.manuallyAddFile(file, process.env.VUE_APP_API_STORAGE_URL+tryOnImage.image);
					}
				}
			},
			async getVariantList() {
				var vm = this;
				var response = await OptionGroupService.getVariantList(vm.id);
				if(response){
					for(const variant of response) {
						var color = variant.optionValues.filter((e)=>e.option_group_id == 2)
						if(color.length != 0){
							var excistingColor = vm.variants.filter((e)=>e.id == color[0].option_value_id);
							if(excistingColor.length==0)
								vm.variants.push({
									id: color[0].option_value_id,
									label: color[0].option_value
								})
						}
					}
				}
			},
			async getProductById() {
				var vm = this;
				var response = await ProductService.getProductById(this.id);
				vm.productDetails = response;
			},
			async getImagesByProductId() {
				var vm = this;
				await ImageService.getImagesByProductId(this.id,this.optionValueId).then((response) => {
					vm.imageList = response;
					if(vm.imageList.length != 0){
						vm.images = vm.imageList.filter((e) => e.image_type == "normal") 
						vm.images.map((e)=>{
							e.image = vm.imgPath+e.image
						})
					}
					vm.sort();
					vm.initImages();
					vm.loading = false;
				}).catch((err)=>{
					vm.loading = false;
				});
			},
			sendingDefaultEvent (file, xhr, formData) {
				formData.append('image_type', 'default');
				formData.append('option', this.optionValueId);
			},
			sendingTryOnEvent (file, xhr, formData) {
				formData.append('image_type', 'try_on');
				formData.append('option', this.optionValueId);
			},
			sendingSideEvent (file, xhr, formData) {
				formData.append('image_type', 'side');
				formData.append('option', this.optionValueId);
			},
			sendingMultipleEvent (file, xhr, formData) {
				formData.append('image_type', 'normal');
				formData.append('option', this.optionValueId);
			},
			removeAllFile(id){
				this.$refs[id].removeAllFiles();
			},
			multipleComplete(response){
				this.destroy()
				this.getImagesByProductId();
			},
			removed(file){
				if(file.id != undefined)
					ImageService.deleteImage(file.id);
			},
			deleteImage(id){
				var vm = this;
				Swal.fire({
					icon: 'warning',
					title: 'Are you sure?',
					text: "You won't be able to revert this!",
					showCancelButton: true,
					confirmButtonText: 'Yes, delete it!'
				}).then((result) => {
					if (result.isConfirmed) {
						ImageService.deleteImage(id)
							.then(function (response) {
									vm.destroy()
									vm.getImagesByProductId();
									let timerInterval;
									Swal.fire({
									title: response.message,
									timer: 1000,
									icon: "success",
									timerProgressBar: true,
									showConfirmButton: false,
									showCancelButton: false,
									didOpen: () => {
										Swal.showLoading();
										const b = Swal.getHtmlContainer().querySelector("b");
										timerInterval = setInterval(() => {
										b.textContent = Swal.getTimerLeft();
										}, 100);
									},
									willClose: () => {
										clearInterval(timerInterval);
									},
									}).then((result) => {
									/* Read more about handling dismissals below */
									if (result.dismiss === Swal.DismissReason.timer) {
									}
									});
							})
							.catch(function () {
							});
					}
				});
				
			},
			destroy() {
				this.$refs.defaultDropzone.removeAllFiles();
				this.$refs.sideViewDropzone.removeAllFiles();
				this.$refs.tryOnDropzone.removeAllFiles();
				this.$refs.multipleImageDropzone.removeAllFiles();
			}
		},
		watch:{
			optionValueId: function(val) {
				this.loading = true;
				if(this.$refs.defaultDropzone != undefined)
					this.destroy();
				this.images = [];
				this.getImagesByProductId()
			}
		},
	};
</script>

<style>
	.list-item{
		    background: #ececec;
			padding: 1rem;
			border-radius: 5px;
	}
	.list-item ul li a i{
			font-size: 1.5rem;
	}
	.vue-dropzone{
		text-align: center;
	}
	.dropzone .dz-preview {
		display: block;
	}
	.dropzone .dz-preview .dz-image {
		width: 100%;
		height: 100px;
		object-fit: contain;
	}
</style>