import ApiService from "@/core/services/api.service.js";

const optionGroupService = {
    getOptionGroupList() {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/optionGroups")
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    getVariantList(id) {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/productOptions/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    getVariantById(id) {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/productOptions/detail/"+id)
            .then(function(response) {
              if (response.data.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    createVariant(data){
        return  new Promise((resolve, reject) => {
            ApiService.post("api/admin/productOption/create",data)
            .then(function(response) {
              if (response.data.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    updateVariant(id,req) {
        return  new Promise((resolve, reject) => {
            ApiService.put("api/admin/productOptions/update/"+id,req)
            .then(function(response) {
              if (response.data.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    deleteVariant(id) {
        return  new Promise((resolve, reject) => {
          ApiService.delete("api/admin/productOptions/delete/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    }
}

export default optionGroupService;