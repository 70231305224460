import ApiService from "@/core/services/api.service.js";

const ImageService = {
    getImagesByProductId(productId,optionValueId) {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/product/optionImages/"+productId+"/"+optionValueId)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    updateSortOrder(id,req) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/product/image/updateOrder/"+id,req)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    deleteImage(id) {
      return  new Promise((resolve, reject) => {
        ApiService.delete("api/admin/product/image/delete/"+id)
          .then(function(response) {
            if (response.data) {
              resolve(response.data);
            } else {
              resolve("");
            }
          })
          .catch(function(error) {

            reject(error)
          });
      });
  },
}

export default ImageService;